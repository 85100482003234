import {  Text, SimpleGrid, VStack, Box } from "@chakra-ui/react";

import './question.css';


function Question({ q, setAnswer, answer }) {
  
  const registerAnswer = (option) => {
    setAnswer(option)
  }

  return (
      <VStack w="100%">
        <Text fontSize={[ "md","lg", "xl"]} color="#344356" style={{ textAlign: 'Center', fontWeight: 'Bold' }}>{q.q}</Text>
        <SimpleGrid columns={[1,2]} spacing={5} w="100%" minW="70vw" style={{marginTop:"2rem"}}>
          {q.options.map((option , index) =>
            <Box
              key={index}
              border="1px"
              borderColor="grey"
              borderRadius=".6rem"
              onClick={() => { option.id === answer.id ? registerAnswer({}) : registerAnswer({...option}) }}
              w="100%"
              min-h="80px"
              className="container"
              bg={answer.id  === option.id  ? "#344356" : "#f9f5f6"}
              color={answer.id  === option.id ? "white" : "#344356"}
              p={2}
            >
              <Text p={2} fontSize={["sm", "md", "lg"]}>{option.option}</Text>
            </Box>
          )}
        </SimpleGrid>
      </VStack>
  )
}

export default Question;