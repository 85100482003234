import { Text, VStack, Center, Grid, GridItem, Button, Image } from "@chakra-ui/react"
import Quiz from './Quiz.js';
import logo from '../assets/blackLotusLogo2.png'


function Layout() {
  return (
    <Grid templateRows={["20vh 1fr 15vh 3vh","15vh minmax(300px,65vh) 15vh  3vh"]} minH="98vh" >
      <GridItem alignSelf="center" justifySelf="center">
        <Image width="250px"  alt="Black Lotus" src={logo} />        
      </GridItem>
      <GridItem >
        <Quiz></Quiz>
      </GridItem>
      <GridItem alignSelf="end">
        <Center>
          <VStack>
            <Text fontSize={["md","lg","xl"]} fontStyle="bold">Get the App</Text>
            <span>
            <a href="https://play.google.com/store/apps/details?id=com.rt.pinprickeffect.meditation&hl=en_IN" target="_blank" rel="noopener noreferrer">
              <Button width={["6rem", "10rem"]}  variant="outline" border="2px" _hover={
                {
                  color: "white",
                  bg: '#f9801b'
                }
              }
                m="2">
                <img style={{ height: "1rem" }} src="https://blacklotus.app/wp-content/uploads/2021/03/andriod-icon.png" alt=""></img>
                Android
              </Button>
              </a>
              <a href="https://apps.apple.com/us/app/black-lotus/id1225659190" target="_blank" rel="noopener noreferrer">

              <Button  width={["6rem", "10rem"]}  variant="outline" border="2px" _hover={
                {
                  color: "white",
                  bg: '#f9801b'
                }
              }
                m="2">
                <img style={{ height: "1rem" }} src="https://blacklotus.app/wp-content/uploads/2021/04/ios-icon-1.png" alt=""></img>
                iOS
              </Button>
              </a>
            </span>
          </VStack>
        </Center>
      </GridItem>
      <GridItem >
        <Center p={1}  bg="#f9f5f6">
          <p style={{ color: "#434343", textAlign: "center", fontSize: "14px" }}>© 2021 Black Lotus</p>
        </Center>
      </GridItem>
    </Grid>
  )
}

export default Layout;