import { Box, Center, Grid, GridItem, Progress, Text, useToast  } from "@chakra-ui/react";
import { useState } from "react";
import Question from './Question';
import quizData from './quiz.json';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import CompleteModal from "./CompleteModal";
import { Swipeable, defineSwipe  } from 'react-touch';


function Quiz() {
  const [currIndex, setIndex] = useState(0);
  const swipe = defineSwipe({swipeDistance: 10});
  const [answersList, setAnswersList] = useState(new Array(quizData.total).fill({}));
  const toast = useToast();

  const next = () => {
    if (currIndex >= quizData.total - 1) return;
    setIndex(currIndex + 1)
  }
  const prev = () => {
    if (currIndex <= 0) return;
    setIndex(currIndex - 1)
  }

  const setAnswer = (value) => {
    const newAnswerList = [...answersList];
    newAnswerList[currIndex] = value
    setAnswersList(newAnswerList)
  };

  const submitAnswers = (values) => {
    values['data'] = answersList;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values)
  };
  fetch(`${process.env.REACT_APP_API_URL}/surveys`, requestOptions)
      .then(response => {
        toast({
          title: "Thank you!",
          description: "You will be getting an email shortly with your results",
          status: "success",
          duration: 5000,
          isClosable: true,
        })
      })
      .catch(e => {
        toast({
          title: "Oops!",
          description: "Something has gone wrong, please check and try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        })
      })
  }

  return (
    <>
       {currIndex === 0 && <Center>
        <Text fontSize={["3xl", "2xl", "4xl"]} color="#f6993e" style={{ marginTop: "1rem" }}>Mindfulness Quiz</Text>
      </Center>}
      <Swipeable config={swipe} onSwipeRight={prev} onSwipeLeft={next}>
        <Grid templateColumns="10vw 1fr 10vw" templateRows={["1fr 10vh", "40vh 20vh"]} mt="1.5rem" >
          <GridItem alignSelf="center" justifySelf="center">
            {currIndex !== 0 &&
              <Box onClick={() => prev()}
                _hover={
                  { cursor: 'pointer' }
                }>
                <ChevronLeftIcon fontSize="3rem"></ChevronLeftIcon>
              </Box>}
          </GridItem>
          <GridItem  >
            <Question q={quizData.questions[currIndex]} answer={answersList[currIndex]} setAnswer={setAnswer}></Question>
          </GridItem>
          <GridItem alignSelf="center" justifySelf="center">
            {currIndex !== quizData.total - 1 &&
              <Box onClick={() => next()}
                _hover={
                  { cursor: 'pointer' }
                }>
                <ChevronRightIcon fontSize="3rem"></ChevronRightIcon>
              </Box>
            }
          </GridItem>
          <GridItem colStart="2" mt="4"  >
            <Progress w="80vw" colorScheme="orange" bg="#dddedd" height="5px" value={(Number(currIndex + 1) / Number(quizData.total)) * 100} />
            <Center >
              <Text size="md">{currIndex + 1}/{quizData.total}</Text>
            </Center>
            {currIndex === quizData.total - 1 &&
              (<Center ><CompleteModal submitForm={submitAnswers}></CompleteModal></Center>)}
          </GridItem>
        </Grid>


      </Swipeable>
    </>
  )
}

export default Quiz;

