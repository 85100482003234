import {
  Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter,
  useDisclosure, Stack, Input, FormControl, FormLabel, Select
} from "@chakra-ui/react";


function CompleteModal({submitForm}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const onSubmit = (e) => {
    const formData = new FormData(e.currentTarget);
    e.preventDefault();
    let result = {};
    for (let [key, value] of formData.entries()) {
      result[key] = value;
    }
    submitForm(result);
    onClose();
  }
  return (
    <>
      <Button
        w={"50%"}
        m="2"
        color="#f6993e"
        bg="white"
        border="1px"
        onClick={onOpen}
        _hover={{
          bg: "#f6993e",
          color: "white"
        }}>
        Done
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"} >
        <ModalOverlay />
        <ModalContent  >
          <ModalHeader p="6" >We want to send you your results.</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={onSubmit}>
              <Stack spacing={3}>
                <FormControl id="name">
                  <FormLabel>1. Name</FormLabel>
                  <Input required focusBorderColor="#fe7567" bg="#f9f5f6" type="text" name="name" />
                </FormControl>
                <FormControl id="email">
                  <FormLabel>2. Email address</FormLabel>
                  <Input required focusBorderColor="#fe7567" bg="#f9f5f6" type="email" name="email" />

                </FormControl>
                <FormControl id="age">
                  <FormLabel>3. Age</FormLabel>
                  <Input required focusBorderColor="#fe7567" bg="#f9f5f6" type="number" name="age" />
                </FormControl>
                <FormControl as="fieldset">
                  <FormLabel>4. What option suits you the best?</FormLabel>
                  <Select required focusBorderColor="#fe7567" bg="#f9f5f6" placeholder="Select option" name="occupation">
                    <option value="Student">Student</option>
                    <option value="Working Professional">Working Professional</option>
                    <option value="Retired">Retired</option>
                    <option value="Just exploring life">Just exploring life</option>
                    <option value="Others">Others</option>
                  </Select>
                </FormControl>
                <Button
                  type="submit"
                  color="#f6993e"
                  bg="white"
                  border="1px"

                  _hover={{
                    bg: "#f6993e",
                    color: "white"
                  }}
                >Send</Button>
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CompleteModal;