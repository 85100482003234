
import './App.css';

// 1. import `ChakraProvider` component
import { ChakraProvider  } from "@chakra-ui/react"
import Layout from './Common/Layout';


function App() {
  return (
    <div className="App" >
      <ChakraProvider>
        <Layout></Layout>
      </ChakraProvider>
          </div>
            );
}

            export default App;
